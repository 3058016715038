import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SubmitHandler } from 'react-hook-form';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useRouter } from '../../../hooks/useRouter';
import { useActiveTab } from '../../../hooks/useActiveTab';
import { useAuthContext } from '../../../contexts/Auth0Context';
import { useUserContext } from '../../../contexts/UserContext';

import { validateSubmitedValues } from '../../../utils/form';
import { userFormProperties } from '../../../types/user';
import { Urls } from '../../urls';
import { Colors } from '../../../styles/colors';
import { formatDate } from '../../../utils/date';

import { Plan, StripeSubscription, StripeInvoice } from '../../../generated/types';

import { useUpdateUser } from '../../../graph/mutations/updateUser';
import { useGetMe } from '../../../graph/queries/getMe';
import { useDangerDeleteFullAccount } from '../../../graph/mutations/dangerDeleteFullAccount';
import { useLazyGetInvoices } from '../../../graph/queries/getInvoices';
import { useLazyGetSubscription } from '../../../graph/queries/getSubscription';
import { useRestoreMembership } from '../../../graph/mutations/restoreMembership';

import { SkeletonBlock } from '../../../components/atoms/Skeleton/SkeletonBlock';
import {
  PrimaryButton,
  SecondaryButton,
  Link as LinkDecorated,
  WrapperButtons,
  LinkButton,
} from '../../../components/atoms/Button/Buttons';
import { TextInput } from '../../../components/atoms/Form/TextInput/TextInput';
import { Form } from '../../../components/atoms/Form/Form';
import { UploadAvatar } from '../../../components/molecules/UploadAvatar';
import { TabController, TabPanel } from '../../../components/atoms/Tabs';
import {
  Wrapper,
  WrapperLeft,
  WrapperRight,
  WrapperLeftRight,
  DangerZone,
  SocialWrapper,
} from './ChangeUserDetail.styles';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { CheckboxInput } from '../../../components/atoms/Form/CheckboxInput';
import { Paper } from '../../../components/atoms/Paper';
import { H4, Copy, H3 } from '../../../components/atoms/Typography';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { ChevronRightIcon, GoogleFullIcon, LinkedinIcon } from '../../../components/atoms/Icons';
import { CopyColoredSpan } from '../../../components/atoms/Typography/Copy';
import { GET_ME } from '../../../graph/queries/me';
import { useCancelMembership } from '../../../graph/mutations/cancelMembership';
import { CreditsHistory } from '../../../components/atoms/CreditsHistory';
import { TinyLoader } from '../../../components/atoms/Loader';

export const ChangeUserDetail: React.FC<{}> = () => {
  const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<any[]>([]);
  const [subscription, setSubscription] = useState<StripeSubscription>();
  const [activeTab, setActiveTab] = useActiveTab('general');
  const user = useUserContext();
  const { signOut } = useAuthContext();
  const { navigate } = useRouter();
  const { loading, error, data } = useGetMe();
  const [updateUser] = useUpdateUser();
  const [dangerDeleteFullAccount] = useDangerDeleteFullAccount();
  const [avatar, setAvatar] = useState<string>('');
  const [getInvoices] = useLazyGetInvoices();
  const [getSubscription] = useLazyGetSubscription();
  const [restoreMembership] = useRestoreMembership();
  const [cancelMembership] = useCancelMembership();
  const membershipPlan = user?.membershipPlan as Plan;

  useEffect(() => {
    async function fetchStripe() {
      setInvoicesLoading(true);
      setSubscriptionLoading(true);
      try {
        const { data: { invoices } = {} } = await getInvoices({
          fetchPolicy: 'network-only',
        });
        const { data: { subscription } = {} } = await getSubscription({
          fetchPolicy: 'network-only',
        });
        setInvoices(invoices || []);
        if (subscription) {
          setSubscription(subscription);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setInvoicesLoading(false);
        setSubscriptionLoading(false);
      }
    }
    if (activeTab === 'membership') {
      fetchStripe();
    }
  }, [activeTab]);

  const onSubmitGeneral: SubmitHandler<FormDataGeneral> = async (data) => {
    const dataWithAvatar = {
      ...data,
      avatar,
    };
    const formattedFormValues = validateSubmitedValues(dataWithAvatar, userFormProperties);

    try {
      await updateUser({
        variables: {
          data: formattedFormValues,
        },
      });
      toast.success('General Settings updated successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update your general settings. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    }
  };

  const onSubmitNotification: SubmitHandler<FormDataNotification> = async (data) => {
    const formattedFormValues = validateSubmitedValues(data, userFormProperties);

    try {
      await updateUser({
        variables: {
          data: formattedFormValues,
        },
      });
      toast.success('Notifications Settings updated successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update your notifications settings. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    }
  };

  const onSubmitDanger: SubmitHandler<FormDataDanger> = async (data) => {
    try {
      toast.success('Deleting in progress...');

      await dangerDeleteFullAccount();
      signOut();
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete your full account. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    }
  };

  const handleRestoreMembership = async () => {
    try {
      await restoreMembership({
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ME,
          },
        ],
      });
      toast.success('Your membership has been restored successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to restore your membership. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    }
  };

  const handleCancelMembership = async () => {
    try {
      await cancelMembership({
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_ME,
          },
        ],
      });
      toast.success('Your membership has been cancelled successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to cancel your membership. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    }
  };

  let child = <></>;

  if (loading) {
    child = (
      <>
        <SkeletonBlock style={{ height: '0px', marginBottom: '70px' }} />
        <SkeletonBlock style={{ height: '60px', marginBottom: '20px' }} />
        <SkeletonBlock style={{ height: '60px', marginBottom: '20px' }} />
        <SkeletonBlock style={{ height: '200px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '100px', marginBottom: '0' }} />
      </>
    );
  } else if (error) {
    child = <p>Error :(</p>;
  } else if (data) {
    child = (
      <>
        <TabController
          headers={[
            { id: 'general', label: 'General' },
            { id: 'notification', label: 'Notifications' },
            { id: 'membership', label: 'Membership' },
            { id: 'danger', label: 'Danger' },
          ]}
          initialActiveId={activeTab}
          onTabChanged={(tabId) => {
            setActiveTab(tabId);
          }}
        >
          <TabPanel $tabId="general">
            {/* <pre>{JSON.stringify(data.me, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(updatedUserState, null, 2)}</pre> */}
            <Form onSubmit={onSubmitGeneral} validationSchema={validationSchemaGeneral}>
              <H3>General Settings</H3>
              <WrapperLeftRight>
                <WrapperLeft>
                  <TextInput name="name" defaultValue={data.me?.name || ''} type="text" label="Full name" />
                  <TextInput name="phone" defaultValue={data.me?.phone || ''} type="text" label="Phone Number" />
                  <TextInput
                    name="dateOfBirth"
                    defaultValue={data?.me?.dateOfBirth || ''}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Date of birth"
                  />
                  <H4>Social Profiles</H4>
                  <Paper>
                    <Copy>
                      <SocialWrapper>
                        <GoogleFullIcon color={Colors.Primary} size={1.4} />
                        {data?.me?.hasGoogleAuth ? (
                          <>Google connection on {formatDate({ date: data.me?.hasGoogleAuthAt, format: 'long' })}</>
                        ) : (
                          <>No Google Auth connection</>
                        )}
                      </SocialWrapper>
                      <Spacer y={16} />
                      <SocialWrapper>
                        <LinkedinIcon color={Colors.Primary} size={1.7} />
                        {data?.me?.hasLinkedinAuth ? (
                          <>Linkedin connection on {formatDate({ date: data.me?.hasLinkedinAuthAt, format: 'long' })}</>
                        ) : (
                          <>No Linkedin Auth connection</>
                        )}
                      </SocialWrapper>
                    </Copy>
                  </Paper>
                  <Spacer y={32} />
                </WrapperLeft>
                <WrapperRight>
                  <UploadAvatar avatar={data.me?.avatar || ''} setAvatar={setAvatar} />
                </WrapperRight>
              </WrapperLeftRight>
              <WrapperLeftRight>
                <WrapperLeft>
                  <WrapperButtons>
                    <SecondaryButton
                      inline
                      onClick={() => {
                        navigate(`/${Urls.Dashboard}`);
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} />}>
                      Submit
                    </PrimaryButton>
                  </WrapperButtons>
                </WrapperLeft>
                <WrapperRight></WrapperRight>
              </WrapperLeftRight>
            </Form>
          </TabPanel>
          <TabPanel $tabId="notification">
            <Form onSubmit={onSubmitNotification} validationSchema={validationSchemaNotification}>
              <H3>Activate Notifications</H3>
              <WrapperLeftRight>
                <WrapperLeft>
                  <CheckboxInput
                    isChecked={data.me?.enableEmailNotification || false}
                    name="enableEmailNotification"
                    label={
                      <>
                        <Copy marginBottom={0} styleLevel={2}>
                          Receive important updates and alerts related to your Job Search via email.
                        </Copy>
                        <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                          Job created, Contact added, Event created, etc.
                        </Copy>
                      </>
                    }
                    noMargin
                  />
                  <CheckboxInput
                    isChecked={data.me?.enableEmailDailySummaryEmail || false}
                    name="enableEmailDailySummaryEmail"
                    label={
                      <>
                        <Copy marginBottom={0} styleLevel={2}>
                          Receive a daily Summary of your Job Search via email.
                        </Copy>
                        <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                          Useful daily resume of your Job Search! Straight to your inbox every day.
                        </Copy>
                      </>
                    }
                    noMargin
                  />
                  <CheckboxInput
                    isChecked={data.me?.enableEmailWeeklySummaryEmail || false}
                    name="enableEmailWeeklySummaryEmail"
                    label={
                      <>
                        <Copy marginBottom={0} styleLevel={2}>
                          Receive a weekly Summary of your Job Search via email.
                        </Copy>
                        <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                          Useful weekly resume of your Job Search! Straight to your inbox every week.
                        </Copy>
                      </>
                    }
                    noMargin
                  />
                  <CheckboxInput
                    isChecked={data.me?.enableMarketingEmail || false}
                    name="enableMarketingEmail"
                    label={
                      <>
                        <Copy marginBottom={0} styleLevel={2}>
                          Stay updated on exciting offers.
                        </Copy>
                        <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                          Discounts, special offers, new features and more.
                        </Copy>
                      </>
                    }
                    noMargin
                  />
                </WrapperLeft>
                <WrapperRight>
                  <CheckboxInput
                    isChecked={data.me?.enablePushNotification || false}
                    name="enablePushNotification"
                    label={
                      <>
                        <Copy marginBottom={0} styleLevel={2}>
                          Instant Push Notifications for urgent information.
                        </Copy>
                        <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                          Job created by a recruiter, new contact added by a recruiter, etc.
                        </Copy>
                      </>
                    }
                    noMargin
                  />
                  <CheckboxInput
                    isChecked={data.me?.enableSmsNotification || false}
                    name="enableSmsNotification"
                    label={
                      <>
                        <Copy marginBottom={0} styleLevel={2}>
                          Instant Text Message Alerts (SMS) for urgent information.
                        </Copy>
                        <Copy marginBottom={0} styleLevel={3} isItalic={true}>
                          Job matched by our AI, etc.
                        </Copy>
                      </>
                    }
                    noMargin
                  />
                </WrapperRight>
              </WrapperLeftRight>
              <WrapperLeftRight>
                <WrapperLeft>
                  <WrapperButtons>
                    <SecondaryButton
                      inline
                      onClick={() => {
                        navigate(`/${Urls.Dashboard}`);
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} />}>
                      Submit
                    </PrimaryButton>
                  </WrapperButtons>
                </WrapperLeft>
                <WrapperRight></WrapperRight>
              </WrapperLeftRight>
            </Form>
          </TabPanel>
          <TabPanel $tabId="membership">
            <H3>Plan details</H3>
            <WrapperLeftRight>
              <WrapperLeft>
                <Paper>
                  {[Plan.Free].includes(membershipPlan) && (
                    <>
                      <Copy styleLevel={2} marginBottom={32}>
                        Your current plan is <CopyColoredSpan>Free</CopyColoredSpan>.
                      </Copy>
                      <PrimaryButton
                        type="submit"
                        inline
                        onClick={() => {
                          navigate(`/${Urls.Checkout}`);
                        }}
                      >
                        Upgrade Plan
                      </PrimaryButton>
                    </>
                  )}

                  {[Plan.Trial].includes(membershipPlan) && (
                    <>
                      {subscriptionLoading ? (
                        <Copy styleLevel={1} marginBottom={8}>
                          Checking your Subscription status... <TinyLoader />
                        </Copy>
                      ) : (
                        <Copy styleLevel={1} marginBottom={8}>
                          Your Subscription is <CopyColoredSpan>{subscription?.status}</CopyColoredSpan>
                        </Copy>
                      )}

                      {subscriptionLoading ? (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Trial</CopyColoredSpan>
                        </Copy>
                      ) : (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Trial</CopyColoredSpan> and will be converted into a{' '}
                          <CopyColoredSpan>Premium</CopyColoredSpan> (Monthly) on the{' '}
                          <CopyColoredSpan>
                            {formatDate({
                              date: subscription?.currentPeriodEnd,
                              format: 'long',
                            })}
                          </CopyColoredSpan>
                          .
                        </Copy>
                      )}

                      {subscription && (
                        <>
                          <Copy styleLevel={3}>
                            Last billing cycle was{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: subscription?.currentPeriodStart,
                              })}
                            </CopyColoredSpan>
                          </Copy>
                          <Copy styleLevel={3} marginBottom={32}>
                            Next billing cycle is{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: subscription?.currentPeriodEnd,
                              })}
                            </CopyColoredSpan>
                          </Copy>
                        </>
                      )}
                      {invoices.map((invoice: StripeInvoice) => {
                        return (
                          <Copy styleLevel={3} marginBottom={32} key={invoice.id}>
                            Invoice #{invoice.number} -{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: invoice.createdAt,
                              })}
                            </CopyColoredSpan>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.hostedInvoiceUrl, '_blank');
                              }}
                            >
                              View
                            </LinkButton>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.invoicePdf, '_blank');
                              }}
                            >
                              Download PDF
                            </LinkButton>
                          </Copy>
                        );
                      })}

                      <PrimaryButton type="submit" inline onClick={handleCancelMembership}>
                        Cancel Trial
                      </PrimaryButton>
                    </>
                  )}

                  {[Plan.Monthly, Plan.Quarterly].includes(membershipPlan) && (
                    <>
                      {subscriptionLoading ? (
                        <Copy styleLevel={1} marginBottom={8}>
                          Checking your Subscription status... <TinyLoader />
                        </Copy>
                      ) : (
                        <Copy styleLevel={1} marginBottom={8}>
                          Your Subscription is <CopyColoredSpan>{subscription?.status}</CopyColoredSpan>
                        </Copy>
                      )}

                      {subscriptionLoading ? (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Premium</CopyColoredSpan>
                        </Copy>
                      ) : (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Premium</CopyColoredSpan> and will be renewed on the{' '}
                          <CopyColoredSpan>
                            {formatDate({
                              date: subscription?.currentPeriodEnd,
                              format: 'long',
                            })}
                          </CopyColoredSpan>
                          .
                        </Copy>
                      )}

                      {subscription && (
                        <>
                          <Copy styleLevel={3}>
                            Last billing cycle was{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: subscription?.currentPeriodStart,
                              })}
                            </CopyColoredSpan>
                          </Copy>
                          <Copy styleLevel={3} marginBottom={32}>
                            Next billing cycle is{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: subscription?.currentPeriodEnd,
                              })}
                            </CopyColoredSpan>
                          </Copy>
                        </>
                      )}
                      {invoices.map((invoice: StripeInvoice) => {
                        return (
                          <Copy styleLevel={3} marginBottom={32} key={invoice.id}>
                            Invoice #{invoice.number} -{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: invoice.createdAt,
                              })}
                            </CopyColoredSpan>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.hostedInvoiceUrl, '_blank');
                              }}
                            >
                              View
                            </LinkButton>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.invoicePdf, '_blank');
                              }}
                            >
                              Download PDF
                            </LinkButton>
                          </Copy>
                        );
                      })}

                      <PrimaryButton type="submit" inline onClick={handleCancelMembership}>
                        Downgrade
                      </PrimaryButton>
                    </>
                  )}

                  {[Plan.CancelledTrial].includes(membershipPlan) && (
                    <>
                      {subscriptionLoading ? (
                        <Copy styleLevel={1} marginBottom={8}>
                          Checking your Subscription status... <TinyLoader />
                        </Copy>
                      ) : (
                        <Copy styleLevel={1} marginBottom={8}>
                          Your Subscription is <CopyColoredSpan>Cancelled</CopyColoredSpan>
                        </Copy>
                      )}

                      {subscriptionLoading ? (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Trial</CopyColoredSpan> but will be downgraded to a{' '}
                          <CopyColoredSpan>Free</CopyColoredSpan> Plan.
                        </Copy>
                      ) : (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Trial</CopyColoredSpan> until the end of your trial
                          cycle on the{' '}
                          <CopyColoredSpan>
                            {formatDate({
                              date: subscription?.cancelAt,
                              format: 'long',
                            })}
                          </CopyColoredSpan>
                          . Then you will be downgraded to a <CopyColoredSpan>Free</CopyColoredSpan> Plan.
                        </Copy>
                      )}

                      {subscription && (
                        <>
                          <Copy styleLevel={3} marginBottom={32}>
                            Last billing cycle was{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: subscription?.currentPeriodStart,
                              })}
                            </CopyColoredSpan>
                          </Copy>
                        </>
                      )}
                      {invoices.map((invoice: StripeInvoice) => {
                        return (
                          <Copy styleLevel={3} marginBottom={32} key={invoice.id}>
                            Invoice #{invoice.number} -{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: invoice.createdAt,
                              })}
                            </CopyColoredSpan>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.hostedInvoiceUrl, '_blank');
                              }}
                            >
                              View
                            </LinkButton>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.invoicePdf, '_blank');
                              }}
                            >
                              Download PDF
                            </LinkButton>
                          </Copy>
                        );
                      })}
                      <PrimaryButton type="submit" inline onClick={handleRestoreMembership}>
                        Restore
                      </PrimaryButton>
                    </>
                  )}

                  {[Plan.CancelledMonthly, Plan.CancelledQuarterly].includes(membershipPlan) && (
                    <>
                      {subscriptionLoading ? (
                        <Copy styleLevel={1} marginBottom={8}>
                          Checking your Subscription status... <TinyLoader />
                        </Copy>
                      ) : (
                        <Copy styleLevel={1} marginBottom={8}>
                          Your Subscription is <CopyColoredSpan>Cancelled</CopyColoredSpan>
                        </Copy>
                      )}

                      {subscriptionLoading ? (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Premium</CopyColoredSpan> but will be downgraded to a{' '}
                          <CopyColoredSpan>Free</CopyColoredSpan> Plan.
                        </Copy>
                      ) : (
                        <Copy styleLevel={2} marginBottom={8}>
                          Your current plan is <CopyColoredSpan>Premium</CopyColoredSpan> until the end of your
                          subscription cycle on the{' '}
                          <CopyColoredSpan>
                            {formatDate({
                              date: subscription?.cancelAt,
                              format: 'long',
                            })}
                          </CopyColoredSpan>
                          . Then you will be downgraded to a <CopyColoredSpan>Free</CopyColoredSpan> Plan.
                        </Copy>
                      )}

                      {subscription && (
                        <>
                          <Copy styleLevel={3} marginBottom={32}>
                            Last billing cycle was{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: subscription?.currentPeriodStart,
                              })}
                            </CopyColoredSpan>
                          </Copy>
                        </>
                      )}
                      {invoices.map((invoice: StripeInvoice) => {
                        return (
                          <Copy styleLevel={3} marginBottom={32} key={invoice.id}>
                            Invoice #{invoice.number} -{' '}
                            <CopyColoredSpan>
                              {formatDate({
                                date: invoice.createdAt,
                              })}
                            </CopyColoredSpan>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.hostedInvoiceUrl, '_blank');
                              }}
                            >
                              View
                            </LinkButton>
                            {' - '}
                            <LinkButton
                              onClick={() => {
                                window.open(invoice.invoicePdf, '_blank');
                              }}
                            >
                              Download PDF
                            </LinkButton>
                          </Copy>
                        );
                      })}
                      <PrimaryButton type="submit" inline onClick={handleRestoreMembership}>
                        Restore
                      </PrimaryButton>
                    </>
                  )}
                </Paper>
                <Paper>
                  <H4>Billing FAQ</H4>
                  <Copy styleLevel={2}>
                    We are looking forward to assisting you. However, before reaching out, save time with these{' '}
                    <LinkDecorated to={`/${Urls.Static}/${Urls.Faqs}`}>billing FAQs</LinkDecorated>
                  </Copy>
                </Paper>
              </WrapperLeft>
              <WrapperRight>
                <Paper>
                  <H4>Usage-based fees</H4>
                  <Copy styleLevel={1} marginBottom={16}>
                    AI Credits
                  </Copy>
                  <Copy styleLevel={2} isBold={true} marginBottom={16}>
                    {user?.credits} / {user?.isMember ? '2000 per month' : '100'} included
                  </Copy>
                  <Copy styleLevel={3} isBold={true}>
                    <strong>Costs</strong>
                  </Copy>
                  <Copy styleLevel={3} isBold={true}>
                    Generate Cover Letter <CopyColoredSpan>10</CopyColoredSpan> credits
                  </Copy>
                  <Copy styleLevel={3} isBold={true}>
                    Process Uploaded Document <CopyColoredSpan>10</CopyColoredSpan> credits
                  </Copy>
                  <Copy styleLevel={3} isBold={true} marginBottom={16}>
                    Generate simple description <CopyColoredSpan>1</CopyColoredSpan> credit
                  </Copy>
                  <Copy styleLevel={2} marginBottom={16}>
                    <CreditsHistory creditsHistory={user?.creditsHistory} />
                  </Copy>
                </Paper>
              </WrapperRight>
            </WrapperLeftRight>
          </TabPanel>
          <TabPanel $tabId="danger">
            <H4>Delete Account</H4>
            <DangerZone>
              <Form onSubmit={onSubmitDanger} validationSchema={validationSchemaDanger}>
                <TextInput name="confirm" defaultValue="" type="text" label="Confirm" />
                <PrimaryButton type="submit" iconRight={<ChevronRightIcon color={Colors.White} />}>
                  DELETE ALL DATA
                </PrimaryButton>
              </Form>
              <Spacer y={32} />
              <Copy styleLevel={2}>
                Deleting your account will permanently remove all of your data, including your opportunities, contacts,
                events and all other information.
              </Copy>
              <Spacer y={32} />
              <Copy color={Colors.ErrorDark} styleLevel={1}>
                Once you delete an account, there is no going back. Please be certain.
              </Copy>
            </DangerZone>
          </TabPanel>
        </TabController>
      </>
    );
  }

  return (
    <Wrapper>
      <Helmet title="Settings" />
      <Breadcrumbs>
        <Breadcrumb>
          <Link to={`/${Urls.Dashboard}`}>Home</Link>
        </Breadcrumb>
        <Breadcrumb>Settings</Breadcrumb>
      </Breadcrumbs>
      {child}
    </Wrapper>
  );
};

const validationSchemaGeneral = Yup.object().shape({
  name: Yup.string().max(50, 'The full name is too Long!').required('This full name is required'),
  phone: Yup.string().max(50, 'The phone number is too Long!'),
  dateOfBirth: Yup.date()
    .nullable()
    .typeError('Expected a value of type ${type} but got an invalid value')
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

const validationSchemaNotification = Yup.object().shape({});

const validationSchemaDanger = Yup.object().shape({
  confirm: Yup.string().oneOf(['confirm'], 'You will need to type `confirm`'),
});

type FormDataGeneral = Yup.InferType<typeof validationSchemaGeneral>;

type FormDataNotification = Yup.InferType<typeof validationSchemaNotification>;

type FormDataDanger = Yup.InferType<typeof validationSchemaDanger>;
