import styled from 'styled-components';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';

export const Wrapper = styled.div`
  padding-block-start: 16px;

  ${media.from_tablet`
    padding-block-start: 20px;
  `}
`;

export const DangerZone = styled.div`
  padding: 16px;
  border: 10px solid ${Colors.ErrorDark};
  outline: 10px solid ${Colors.ErrorLight};
  max-width: 900px;
`;

export const WrapperLeftRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--right-left-global-padding);
`;

export const WrapperLeft = styled.div`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WrapperRight = styled.div`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WrapperInner = styled.div`
  max-width: 100%;
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
