import { CreditsHistoryProps } from './CreditsHistory.types';
import { Container, Table } from './CreditsHistory.styles';
import { formatDate } from '../../../utils/date';

export const CreditsHistory = ({ creditsHistory }: CreditsHistoryProps) => {
  let creditsHistoryReverse = [...(creditsHistory || [])].reverse().slice(0, 10);

  return (
    <Container>
      {/* <pre>{JSON.stringify(creditsHistory, null, 2)}</pre>
      <pre>{JSON.stringify(creditsHistoryReverse, null, 2)}</pre> */}
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Value</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {creditsHistoryReverse?.map((credit: any) => {
            return (
              <tr key={credit.id}>
                <td>
                  {formatDate({
                    date: credit.createdAt,
                  })}
                </td>
                <td>{credit.value}</td>
                <td>{credit.reason}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};
