import { gql, useQuery, QueryHookOptions } from '@apollo/client';

import { Query } from '../../generated/types';

export const GET_ME = gql`
  query me {
    me {
      name
      phone
      email
      uuid
      avatar
      dateOfBirth
      enableEmailNotification
      enableEmailDailySummaryEmail
      enableEmailWeeklySummaryEmail
      enablePushNotification
      enableSmsNotification
      enableMarketingEmail
      hasGoogleAuth
      hasGoogleAuthAt
      hasLinkedinAuth
      hasLinkedinAuthAt
      credits
      extraCredits
    }
  }
`;

export const useGetMe = (options?: QueryHookOptions<Pick<Query, 'me'>>) => useQuery<Pick<Query, 'me'>>(GET_ME, options);
