import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query } from '../../generated/types';

export const GET_INVOICES = gql`
  query getInvoices {
    invoices {
      id
      amountPaid
      createdAt
      hostedInvoiceUrl
      invoicePdf
      total
      status
      number
      periodEnd
      periodStart
    }
  }
`;

export const useGetInvoices = (options?: QueryHookOptions<Pick<Query, 'invoices'>>) =>
  useQuery<Pick<Query, 'invoices'>>(GET_INVOICES, options);

export const useLazyGetInvoices = (options?: QueryHookOptions<Pick<Query, 'invoices'>>) =>
  useLazyQuery<Pick<Query, 'invoices'>>(GET_INVOICES, options);
