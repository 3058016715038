export const userFormProperties = {
  name: 'string',
  phone: 'string',
  dateOfBirth: 'date',
  avatar: 'string',
  enableEmailNotification: 'boolean',
  enableEmailWeeklySummaryEmail: 'boolean',
  enableEmailDailySummaryEmail: 'boolean',
  enablePushNotification: 'boolean',
  enableSmsNotification: 'boolean',
  enableMarketingEmail: 'boolean',
};

export const userProperties = {
  ...userFormProperties,
  id: 'key',
  relation: 'key',
  uuid: 'string',
  createdAt: 'datetime',
  updatedAt: 'datetime',
  isDeleted: 'boolean',
};
