import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation } from '../../generated/types';

export const RESTORE_MEMBERSHIP = gql`
  mutation RestoreMembership {
    restoreMembership
  }
`;

export const useRestoreMembership = (options?: MutationHookOptions<Pick<Mutation, 'restoreMembership'>>) =>
  useMutation<Pick<Mutation, 'restoreMembership'>>(RESTORE_MEMBERSHIP, {
    ...options,
  });
