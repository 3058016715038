import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar-edit';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

import { Sections, Section } from '../../atoms/Section';

export const UploadAvatar = ({ avatar, setAvatar }: UploadAvatarProps) => {
  const [preview, setPreview] = useState<string>('');

  function onClose() {}

  function onCrop(preview: string) {
    setPreview(preview);
    setAvatar(preview);
  }

  function onBeforeFileLoad(elem: any) {
    if (elem.target.files[0].size > 7168000) {
      elem.target.value = '';
      console.error('File is too big!');
    }
  }

  return (
    <Wrapper>
      <Sections>
        <Section>
          <WrapperUpload>
            <Avatar
              label="Choose your avatar"
              onBeforeFileLoad={onBeforeFileLoad}
              width={200}
              height={200}
              // exportSize={200}
              onCrop={onCrop}
              onClose={onClose}
              src={avatar || ''}
            />
          </WrapperUpload>
        </Section>
        <Section>
          <WrapperPreview>{preview && <img src={preview} alt="Preview" />}</WrapperPreview>
        </Section>
      </Sections>
    </Wrapper>
  );
};

interface UploadAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  avatar: string;
  setAvatar: (avatar: string) => void;
}

const Wrapper = styled.section`
  border: 1px dotted ${Colors.Primary};
  margin-bottom: 30px;

  &:hover {
    border: 1px solid ${Colors.Primary};
  }
`;

const WrapperUpload = styled.div`
  margin: 0 8px;
  overflow: hidden;
  padding: 0 10px;
  > div > div {
    width: 100% !important;
  }
`;

const WrapperPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const toDataURL2 = async (url: string): Promise<string> => {
  return fetch(url, { mode: 'cors' })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .catch((error) => {
      console.error(error);
      return '';
    });
};
