import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query } from '../../generated/types';

export const GET_SUBSCRIPTION = gql`
  query getSubscription {
    subscription {
      id
      status
      currentPeriodEnd
      currentPeriodStart
      cancelAtPeriodEnd
      cancelAt
    }
  }
`;

export const useGetSubscription = (options?: QueryHookOptions<Pick<Query, 'subscription'>>) =>
  useQuery<Pick<Query, 'subscription'>>(GET_SUBSCRIPTION, options);

export const useLazyGetSubscription = (options?: QueryHookOptions<Pick<Query, 'subscription'>>) =>
  useLazyQuery<Pick<Query, 'subscription'>>(GET_SUBSCRIPTION, options);
