import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation } from '../../generated/types';

export const DANGER_DELETE_FULL_ACCOUNT = gql`
  mutation DangerDeleteFullAccount {
    dangerDeleteFullAccount
  }
`;

export const useDangerDeleteFullAccount = (options?: MutationHookOptions<Pick<Mutation, 'dangerDeleteFullAccount'>>) =>
  useMutation<Pick<Mutation, 'dangerDeleteFullAccount'>>(DANGER_DELETE_FULL_ACCOUNT, {
    ...options,
  });
