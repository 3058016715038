import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateUserArgs } from '../../generated/types';

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserInputData!) {
    updateUser(data: $data) {
      uuid
      name
      phone
      avatar
      enableEmailNotification
      enableEmailWeeklySummaryEmail
      enableEmailDailySummaryEmail
      enablePushNotification
      enableSmsNotification
      enableMarketingEmail
    }
  }
`;

export const useUpdateUser = (options?: MutationHookOptions<Pick<Mutation, 'updateUser'>, MutationUpdateUserArgs>) =>
  useMutation<Pick<Mutation, 'updateUser'>, MutationUpdateUserArgs>(UPDATE_USER, {
    awaitRefetchQueries: false,
    // refetchQueries: [
    //   {
    //     query: GET_BOARDS,
    //     variables: { where: { isDeleted: false }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
    //   },
    //   {
    //     query: GET_BOARDS,
    //     variables: { where: { isDeleted: true }, sort: { direction: SortDirection.Desc, field: 'createdAt' } },
    //   },
    // ],
    // // onCompleted: (data) => {
    //   console.log('onCompleted useUpdateBoard', data);
    // },
    ...options,
  });
