import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { CreditsHistoryProps } from './CreditsHistory.types';

export const Container = styled.div<CreditsHistoryProps>`
  /* overflow-x: auto;
  width: 90%;
  ${media.from_tablet`
    width: 100%;
  `} */
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;

  tr {
    background-color: ${Colors.Primary};
    color: #ffffff;
    text-align: left;
    border-bottom: 1px solid #dddddd;
    display: flex;
    flex-direction: column;
    ${media.from_tablet`
      flex-direction: row; 
    `}
  }

  thead tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  th,
  td {
    padding: 12px 15px;
  }
`;
