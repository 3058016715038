import { Google } from '@styled-icons/remix-line';
import { Google as GoogleFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function GoogleIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Google />
    </Icon>
  );
}

export function GoogleFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <GoogleFill />
    </Icon>
  );
}
